<template>
  <div class="project-container" ref="projectContainer" v-loading="isLoading">
    <div class="project-item" v-for="item in data" :key="item.id">
      <a
        :target="item.url ? '_blank' : '_self'"
        :href="
          item.url ? item.url : `javascript:alert('该项目无法通过外网访问')`
        "
        class="thumb"
      >
        <img v-lazy="item.thumb" />
      </a>
      <div class="info">
        <h2>
          <a
            :target="item.url ? '_blank' : '_self'"
            :href="
              item.url ? item.url : `javascript:alert('该项目无法通过外网访问')`
            "
            >{{ item.name }}</a
          >
        </h2>
        <a target="_blank" v-if="item.github" :href="item.github">github</a>
        <p v-for="(it, index) in item.description" :key="index">{{ it }}</p>
      </div>
    </div>
    <Empty v-if="data.length === 0 && !isLoading" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import mainScroll from "@/mixins/mainScroll.js";
import Empty from "@/components/Empty";
export default {
  components: {
    Empty,
  },
  mixins: [mainScroll("projectContainer")],
  created() {
    this.$store.dispatch("project/fetchProject");
  },
  computed: mapState("project", ["isLoading", "data"]),
};
</script>
 
<style lang="less" scoped>
@import "~@/styles/var.less";
.project-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.project-item {
  transition: 0.5s;
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  &:hover {
    box-shadow: -1px 1px 5px #000;
    transform: scale(1.01) translate(3px -3px);
    color: inherit;
  }
  .thumb {
    width: 250px;
    min-height: 150px;
    flex: 0 0 auto;
    border-radius: 5px;
    margin-right: 15px;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .info {
    flex: 1 1 auto;
    line-height: 1.7;
    h2 {
      margin: 0;
    }
  }
  .github {
    font-size: 14px;
    color: @primary;
  }
}
</style>